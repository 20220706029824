<template>
  <b-card
    no-body
    class="system-settings-card"
  >
    <div>
      <invite-user-form
        :visible="showForm"
        :resource-id="resourceId"
        @saved="fetchList()"
        @close="onCloseForm"
      />
      <div class="m-2 d-flex justify-content-between table-actions">
        <b-button
          id="create-button"
          variant="primary"
          :class="{
            'icon-disabled': !$can('PerformAs', 'CompanyAdmin'),
          }"
          @click="$can('PerformAs', 'CompanyAdmin') && create()"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('Invite New') }}</span>
        </b-button>

        <b-tooltip target="create-button">
          {{ $can('PerformAs', 'CompanyAdmin') ? $t('Create') : $t('no-action-permission') }}
        </b-tooltip>
        <div>
          <prozess-search-input
            :value="search"
            :placeholder="$t('Search')"
            @input="handleSearch"
          />
        </div>
      </div>
      <div class="m-2">
        <b-table
          ref="refTable"
          :busy="loading"
          :items="items"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
          class="table-responsive"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @sort-changed="handleSortChange"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <template #head()="{ label }">
            <span>{{ $t(label) }}</span>
          </template>

          <template #cell(name)="{ item }">
            <span v-if="item.status === 'Deleted'">{{ item.firstName + ' ' + item.lastName }}</span>
            <router-link
              v-else
              class="text-nowrap"
              :to="'/users/' + item.userUuid"
            >
              {{ item.firstName + ' ' + item.lastName }}
            </router-link>
          </template>

          <template #cell(groups)="{ item }">
            <b-badge
              v-for="group of item.groups"
              :key="group.id"
              variant="secondary"
              style="margin: 4px"
            >
              {{ group.name }}
            </b-badge>
          </template>

          <template #cell(status)="{ item }">
            <b-badge :variant="userStatusBadge[item.status] || 'secondary'">
              {{ $t(item.status) }}
            </b-badge>
          </template>

          <template #cell(mobileNumber)="{ item }">
            <span class="text-nowrap text-primary">
              {{ item.mobileNumber }}
            </span>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="{ item }">
            <div class="text-nowrap">
              <feather-icon
                :id="`edit-row-${item.userUuid}-preview-icon`"
                icon="EditIcon"
                :class="{
                  'icon-disabled': !$can('PerformAs', 'CompanyAdmin'),
                }"
                size="16"
                class="mx-1 cursor-pointer"
                @click="$can('PerformAs', 'CompanyAdmin') && edit(item.userUuid)"
              />
              <b-tooltip
                :title="$can('PerformAs', 'CompanyAdmin') ? $t('Edit') : $t('no-action-permission')"
                :target="`edit-row-${item.userUuid}-preview-icon`"
                placement="left"
              />
              <feather-icon
                :id="`delete-row-${item.userUuid}-preview-icon`"
                icon="TrashIcon"
                size="16"
                :class="{
                  'icon-disabled': !$can('PerformAs', 'CompanyAdmin'),
                }"
                class="mx-1 cursor-pointer"
                @click="$can('PerformAs', 'CompanyAdmin') && remove(item.userUuid)"
              />
              <b-tooltip
                :title="$can('PerformAs', 'CompanyAdmin') ? $t('Delete') : $t('no-action-permission')"
                :target="`delete-row-${item.userUuid}-preview-icon`"
                placement="left"
              />
            </div>
          </template>
        </b-table>
      </div>
      <prozess-pagination
        v-if="!loading"
        class="mx-2 mb-2"
        :class="{ 'd-none': !total }"
        :meta="dataMeta"
        :page="currentPage"
        :total="total"
        :size="perPage"
        @change="handleCurrentPage"
      />
    </div>
  </b-card>
</template>

<script>
import { swalConfirmDeleteOption } from '@/helpers/app'
import employeeService from '@/services/employee'
import userStatusBadge from '@/constants/userStatusBadge'
import listMixinFactory from '@/mixins/list'
import InviteUserForm from './Form.vue'

export default {
  components: {
    InviteUserForm,
  },
  mixins: [
    listMixinFactory({
      routeName: 'users-settings',
      service: employeeService,
    }),
  ],
  data() {
    return {
      userStatusBadge,
      resourceId: null,
      showForm: false,
    }
  },
  methods: {
    create() {
      this.resourceId = null
      this.showForm = true
    },
    edit(id) {
      this.resourceId = id
      this.showForm = true
    },
    remove(id) {
      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(result => {
        if (result.value) {
          this.deleteResource(id)
        }
      })
    },
    onCloseForm() {
      this.resourceId = null
      this.showForm = false
    },
  },
}
</script>
